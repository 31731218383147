import clsx from 'clsx';
import React from 'react';
import ImageWrapper from './Image';

type SocialIconsProps = {
  className?: string;
};

const SocialIcons: React.FC<SocialIconsProps> = ({ className }) => {
  return (
    <div className={clsx('flex', className)}>
      <a
        href="https://www.facebook.com/sunshinecoastQLD"
        target="_blank"
        title="Visit Our Facebook Page"
        rel="noopener noreferrer">
        <ImageWrapper
          src="/icons/vsc/Facebook.svg"
          dimensions={{ width: 40, height: 40 }}
          alt="Facebook link"
          className="size-7"
        />
      </a>
      <a
        href="https://www.instagram.com/visitsunshinecoast"
        target="_blank"
        title="Visit Our Instagram Page"
        rel="noopener noreferrer">
        <ImageWrapper
          src="/icons/vsc/Instagram.svg"
          dimensions={{ width: 40, height: 40 }}
          alt="Instagram link"
          className="size-7"
        />
      </a>
      <a
        href="https://www.youtube.com/@VisitsunshinecoastAustralia"
        target="_blank"
        title="Visit Our Youtube Channel"
        rel="noopener noreferrer">
        <ImageWrapper
          src="/icons/vsc/YouTube.svg"
          dimensions={{ width: 40, height: 40 }}
          alt="YouTube link"
          className="size-7"
        />
      </a>
      <a
        href="https://www.tiktok.com/@sunshinecoastoz"
        target="_blank"
        title="Visit Our TikTok"
        rel="noopener noreferrer">
        <ImageWrapper
          src="/icons/vsc/TikTok.svg"
          dimensions={{ width: 40, height: 40 }}
          alt="TikTok link"
          className="size-7"
        />
      </a>
    </div>
  );
};

export default SocialIcons;
